<template>
    <div>
        <div :class="['sm','xs'].includes($store.getters['app/currentBreakPoint'])?'text-center mt-2':'text-right mt-2'">
           <b-card-text>
       <b-badge style="background:#8CE0B8;border-radius:48px;padding:10px;">
        <span style="color:#0E1116;">{{itembuy.price}} {{itembuy.typeCoin==1?'Crowns':'Goblets'}}</span>
      </b-badge>
      </b-card-text>
        </div>
        <div class="text-center mt-1">
          <b-card-title>{{$t('curlang')=='en'?itembuy.title:itembuy.titlees}}</b-card-title>
        </div>
        <div class="text-center">
          <b-card-sub-title v-html="itembuy.description"></b-card-sub-title>
        </div>
        <b-row>
            <b-col cols="12 mt-2" v-if="itembuy.especial.type!=7 && itembuy.especial.type!=8 && itembuy.especial.type!=9">
                 <b-form-group label="Wallet Invitation Address">
          <b-form-input
          v-model="itembuy.especial.invitationwallet"
            type="text"
            :placeholder="$t('storeTab.walletaddress')"
          />
        </b-form-group>
        <b-form-group label="Wallet to Receive Elite Invitation">
          <b-form-input
          v-model="itembuy.especial.whitelistwallet"
            type="text"
            :placeholder="$t('storeTab.whitelistwallet')"
          />
        </b-form-group>
        <b-form-group label="Twitter Handle">
            <b-form-input
          v-model="itembuy.especial.twitteruser"
            type="text"
            :placeholder="$t('storeTab.twitteruser')"
          />
        </b-form-group>
            </b-col>
               <b-col cols="12 mt-1" v-if="itembuy.especial.type==7">
                 <b-form-group>
          <b-form-input
          v-model="itembuy.especial.invitationwallet"
            type="text"
            :placeholder="$t('storeTab.wlinvitation')"
          />
        </b-form-group>
            </b-col>
               <b-col cols="12 mt-1" v-if="itembuy.especial.type==7">
                 <b-form-group>
          <b-form-input
          v-model="itembuy.especial.twitteruser"
            type="text"
            :placeholder="$t('storeTab.twitteruser')"
          />
        </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {BCardTitle,BCardSubTitle,BCardText,BRow,BCol,BBadge,BFormGroup,BFormInput} from "bootstrap-vue"
export default {
    props:["itembuy","exp"],
    components:{BCardTitle,BCardSubTitle,BCardText,BRow,BCol,BBadge,BFormGroup,BFormInput},
    methods:{

 
    }
}
</script>