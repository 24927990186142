<template>
    <div>
        <div v-if="item.url">
            <b-img center width="150" :src="item.url" fluid/>
        <div class="text-center mt-1">
                <b-card-title class="font-weight-bolder">{{item.title}}</b-card-title>
        </div>
        <div class="text-center">
            <b-card-text v-html="item.description"></b-card-text>
        </div>
        <div class="mt-4 mb-1">
            <b-button class="backgroundBtn"
                pill
                block
                style="padding: 15px" @click="$router.go(0)">
                Close
            </b-button>
        </div>
        </div>
         <div class="text-success text-center mb-2 mt-2" v-else>
            <b-spinner style="width: 3rem; height: 3rem" />
          </div>
        </div>
</template>
<script>
import {BRow,BCol,BImg,BCardTitle,BCardText,BButton,BSpinner} from "bootstrap-vue"
import SellerService from "@/services/SellerService";
  import Vue from 'vue'
  import VueConfetti from 'vue-confetti'
  Vue.use(VueConfetti)
export default {
    props:["itembuy"],
    components:{BRow,BCol,BImg,BCardTitle,BCardText,BButton,BSpinner},
    data(){
        return{
            item:[]
        }
    },
    mounted:async function(){
       await this.insertBuy()
       await this.start()
    },
    methods:{
 start() {
        this.$confetti.start();
      },
      insertBuy:async function(){
        const d = (await SellerService.InsertBuyItem(JSON.parse(localStorage.getItem("auth")).discordId, this.itembuy )).data;
       this.item=d
       console.log(d);
      }
      
    }

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.backgroundBtn {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
  padding: 18px 80px;
}
</style>