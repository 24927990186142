<template>
  <div>
    <small-card-crown v-if="['sm','xs'].includes($store.getters['app/currentBreakPoint'])"/>
    <header-part
    v-if="['sm','xs'].includes($store.getters['app/currentBreakPoint'])?false:true" 
      :title="'Marketplace'"
      :image="require('@/assets/images/illustration/cart.png')"
      :access="2"
      :paragraph="'Buy and collect Whitelist, NFTs and different types of Elite invitations. You use your gathered crown to buy any items in the Eliteemperors store.'"></header-part>
        <div class="text-center">
           <h4 class="font-weight-bolder d-block d-sm-block d-md-none">Store</h4>
          </div>
        <b-tabs content-class="mt-2" pills :fill="['sm','xs'].includes($store.getters['app/currentBreakPoint'])?true:false">
             <b-tab disabled v-if="['sm','xs'].includes($store.getters['app/currentBreakPoint'])?false:true">
            <template #title>
              <h4 class="font-weight-bolder text-white d-none d-md-block" style="margin-top:-5px;">Store</h4>
            </template>
          </b-tab>
    <b-tab active>
      <template #title>
          <span>{{$t("storeTab.items")}}</span>
      </template>
      <items-availables/>
    </b-tab>
     <b-tab>
      <template #title>
          <span>{{$t("storeTab.itemspurchased")}}</span>
      </template>
      <items-purchased />
    </b-tab>
    <b-tab>
      <template #title>
          <span>Gobs Converter</span>
      </template>
      <convertor />
    </b-tab>
 
  </b-tabs>

  </div>
</template>
<script>
import {BTabs,BTab} from "bootstrap-vue"
import ItemsAvailables from "./ItemsAvailables.vue"
import ItemsPurchased from "./ItemsPurchased.vue"
import Convertor from "./Convertor.vue"
import HeaderPart from "@/components/HeaderPart.vue"
import SmallCardCrown from "@/components/SmallCardCrown.vue"
export default {
  components:{BTabs,BTab,ItemsAvailables,ItemsPurchased,HeaderPart,SmallCardCrown,Convertor},
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>
<style>
.dark-layout .nav-pills .nav-item .nav-link.active {
background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
border: transparent;
}
</style>