<template>
  <div>
    <b-row class="match-height">
      <b-col sm="12" v-if="loading">
          <div class="text-center text-success">
           <b-spinner
        style="width: 3rem; height: 3rem;"
        class="mr-1"
        label="Large Spinner"
      />
        </div>
      </b-col>
          </b-row>

                 <b-card v-if="!loading">
        <item-available :item="items"/>
      </b-card>

  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
  BContainer
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import ItemAvailable from "./ItemAvailable.vue"
import SellerService from '@/services/SellerService'
export default {
  components: { BRow, BCol,BButton,ItemAvailable,BSpinner,BCard,BContainer},
  directives: {
    Ripple,
  },
  data(){
    return{
        items:[],
        loading:true
    }
  },
  mounted(){
    this.getItemsStock()
  },
  methods:{
    getItemsStock:async function(){
      const it=(await SellerService.getItemsStock(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.items=it
      this.loading=false
    }
  }
};
</script>